<template>
  <v-container class="bg-section mb-16 pl-16" fluid>
    <v-row>
        <v-col :md="1"></v-col>
      <v-col cols="12" :sm="6" :md="2" class="bg-card ml-md-4 ml-lg-4 text-center">
        <v-img
          :src="require(`@/assets/portal/person-doc.png`)"
          height="160px"
        ></v-img>
        <h6 class="mt-6">
          Mantén organizados tus contratos con Acist.
        </h6>
      </v-col>
      <v-col cols="12" :sm="6" :md="2" class="bg-card mx-md-4 mx-lg-4 text-center">
        <v-img
          :src="require(`@/assets/portal/persons-group.png`)"
          height="160px"
        ></v-img>
        <h6 class="mt-6">
          Mantén el control de los proveedores dede el portal.
        </h6>
      </v-col>
      <v-col cols="10" :md="5" class="pl-1 ml-8 pl-md-16 ml-md-16 pl-lg-16 ml-lg-16">
        <h6>INFORMA A TU PROVEEDOR CON DATOS PRECISOS</h6>
        <h2 class="mr-14 mb-6">
          Coordina quién, cuándo y cómo
        </h2>
        <p class="">
          Deja de picar datos a mano y ofrece un asesoramiento real y de valor.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PortalFeatures",

  data: () => ({}),
};
</script>

<style scoped>
.bg-card {
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
}
</style>
