<template>
  <v-container class="bg-section" fluid>
    <v-row class="justify-center align-center mb-14">
      <v-col cols="10" :md="6" class="cont-img">
        <v-img
          :src="require('@/assets/portal/portal-org.png')"
          contain
          height="420"
        />
      </v-col>
      <v-col cols="10" :md="6" class="mt-md-16">
        <div class="mr-lg-16 mb-14">
          <h6 class="mr-lg-16 pr-lg-16">CONTROLA TUS DOCUMENTOS</h6>
          <h2 class="b-6 mr-lg-16 pr-lg-16">
            Todos tus contratos en un solo sitio
          </h2>
          <p class="mr-lg-16 pr-lg-16">
            Acist clasifica y ordena todos los
            documentos que has creado dentro de la plataforma para que puedas
            acceder a ellos fácilmente siempre que lo necesites.
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PortalSection",

  data: () => ({}),
};
</script>

<style scoped>
.cont-img {
  text-align-last: right;
}
</style>
