<template>
  <v-container class="pa-0" fluid>
    <BannerPortal />
    <PortalSection />
    <PortalDoc />
    <PortalFeatures />
    <AllYouNeed />
  </v-container>
</template>

<script>
  import BannerPortal from '../components/sections/BannerPortal.vue'
  import AllYouNeed from '../components/sections/AllYouNeed.vue'
  import PortalSection from '../components/sections/PortalSection.vue'
  import PortalDoc from '../components/sections/PortalDoc.vue'
  import PortalFeatures from '../components/sections/PortalFeatures.vue'


  export default {
    name: 'Portal',

    components: {
      BannerPortal,
      PortalSection,
      AllYouNeed,
      PortalFeatures,
      PortalDoc
    },
  }
</script>